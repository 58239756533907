import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCompanyInfo } from '../../store/actions/companyActions'
import Form from '../../components/reusableElementsComponents/Form';
import Button from '../../components/reusableElementsComponents/Button';
import Loading from '../../components/reusableElementsComponents/Loading';
import Loader from '../../components/reusableElementsComponents/Loader';
import MazhrDD from '../../components/formComponents/MazhrDD';
import person from '../../assets/images/icons/person.svg';
import letter from '../../assets/images/icons/letter.svg';
import phone from '../../assets/images/icons/phone.svg';
import company from '../../assets/images/icons/companyIcon.svg';
import logoPlaceholder from '../../assets/images/logos/logo-placeholder.png';
import * as Utilities from "../../Utilities";
import * as Constants from '../../constants'
import B2BApiService from "../../B2bApiService";
import { toast } from "react-toastify";

import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';

function Settings() {
  const data = useSelector(state => state.contentfulData.all['settings']);

  // Get user data
  const [userData, setUserData] = useState({});
  const [template, setTemplate] = useState({});
  const [loader, setLoader] = useState(false);
  const [anonymouseResultsStatus, setAnonymouseResultsStatus] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [industries, setIndustries] = useState({});
  const [industryId, setIndustryId] = useState('');
  const companyData = useSelector(state => state.company.info);
  const { name } = companyData
  const b2bServiceObj = useMemo(() => {
    return new B2BApiService();
  }, []);

  if (!name) {
    b2bServiceObj.LoadCompanyData();
  }

  const dispatch = useDispatch();

  const onSubmit = (values) => {
    setLoader(true);
    const savingDetails = toast.loading("Updating details...")

    if( b2bServiceObj.SaveUserData({
      "first":values.firstname.length > 0 ? values.firstname : userData.first,
      "last":values.lastname.length > 0 ? values.lastname : userData.last,
      "email":values.emailaddress.length > 0 ? values.emailaddress : userData.email,
      "anonymous_results" : anonymouseResultsStatus
    })){
      toast.update(savingDetails, { render: "Setting details saved", type: "success", isLoading: false, autoClose: '2000', closeButton: true, hideProgressBar: true });
    }



        Utilities.toDataUrl(document.getElementById('comapnyLogo')?.src, async function (imageData) {
          let editedData = {
            "company_name": values.companyname.length > 0 ? values.companyname : companyData.name,
            "employee_count": document.getElementById('employeenumber').value,
            "contact_phone": values.phonenumber.length > 0 ? values.phonenumber : '',
            "industry_id": industryId? industryId: companyData?.industry?.id,
            "logo": imageData
          };


        b2bServiceObj.SaveCompanyData(editedData,(data) =>{
          if(data){
            dispatch(setCompanyInfo(
                {
                  name: companyData.name,
                  employee_count: companyData.employee_count,
                  industry: companyData.industry,
                  contact_phone: companyData.contact_phone,
                  logo: companyData.logo,
                }
              ))
              toast.update(savingDetails, { render: "Setting details saved", type: "success", isLoading: false, autoClose: '2000', closeButton: true, hideProgressBar: true });
            } else {
              toast.update(savingDetails, { render: "Unable to save changes", type: "error", isLoading: false, autoClose: '2000', closeButton: true, hideProgressBar: true });
            }
          })
          setLoader(false);
          window.scrollTo(0, 0);
        });

        if(!isUploaded){

          let editedData = {
            "company_name": values.companyname.length > 0 ? values.companyname : companyData.name,
            "employee_count": document.getElementById('employeenumber').value,
            "contact_phone": values.phonenumber.length > 0 ? values.phonenumber : '',
            "industry_id": industryId? industryId: companyData?.industry?.id,
          };


        b2bServiceObj.SaveCompanyData(editedData,(data) =>{
          if(data){
            dispatch(setCompanyInfo(
                {
                  name: companyData.name,
                  employee_count: companyData.employee_count,
                  industry: companyData.industry,
                  contact_phone: companyData.contact_phone,
                  logo: companyData.logo,
                }
              ))
              toast.update(savingDetails, { render: "Setting details saved", type: "success", isLoading: false, autoClose: '2000', closeButton: true, hideProgressBar: true });
            } else {
              toast.update(savingDetails, { render: "Unable to save changes", type: "error", isLoading: false, autoClose: '2000', closeButton: true, hideProgressBar: true });
            }
          })
          setLoader(false);
          window.scrollTo(0, 0);
        }
  }

  const uploadedStateFunc = (state) => {
    setIsUploaded(state)
  }

  const handleAnonymousResultChange = (event) =>{
    setAnonymouseResultsStatus(event.target.checked)
  }

  const onSelectIndustry = (industryName) => {
    setIndustryId(Utilities.getIndustryId(industries, industryName));
  }



  useEffect(() => {
    b2bServiceObj.LoadIndustriesList(setIndustries)
    b2bServiceObj.LoadUserData((userData) => {
      setUserData(userData)
      setAnonymouseResultsStatus(userData.anonymous_results)
    })
    b2bServiceObj.LoadCompanyData()
  }, [b2bServiceObj]);

  useEffect(() => {
    if (data !== undefined && !Utilities.isEmptyObject(data) && !Utilities.isEmptyObject(userData) && !Utilities.isEmptyObject(companyData)) {
      let content = data.fields;
      setTemplate({
        fields: [
          {
            title: 'company logo',
            type: 'file',
            name: 'companylogo',
            placeholder: logoPlaceholder,
            singleImage: true,
            defaultValue: companyData?.logo.length > 0 ? Constants.IMAGES_BASE_URL + companyData.logo : ''
          },
          {
            title: content.firstName,
            type: 'text',
            name: content.firstName,
            placeholder: content.inputPlaceholder,
            icon: person,
            defaultValue: userData.first,

          },
          {
            title: content.lastName,
            type: 'text',
            name: content.lastName,
            placeholder: content.inputPlaceholder,
            icon: person,
            defaultValue: userData.last,

          },
          {
            title: content.email,
            type: 'email',
            name: content.email,
            placeholder: content.inputPlaceholder,
            defaultValue: userData.email,
            icon: letter,

          },
          {
            title: content.companyName,
            type: 'text',
            name: content.companyName,
            placeholder: content.inputPlaceholder,
            defaultValue: companyData?.name,
            icon: company
          },
          {
            title: content.emploees,
            type: 'text',
            name: content.emploees,
            placeholder: content.teamSizePlaceholder,
            dropdown: true,
            defaultValue: companyData?.employee_count,
            dropdownTemplate: content.emploeesDropdownFields
          },
          {
            title: content.phoneNumber,
            type: 'tel',
            name: content.phoneNumber,
            placeholder: content.inputPlaceholder,
            defaultValue: companyData?.contact_phone,
            icon: phone
          }
        ]
      })
    }

    else {
      <Loading />
    }
  }, [companyData, userData, data])

  return (
    <>
      {
        userData.first ? <div className="settings-page">
          {!Utilities.isEmptyObject(template) && <div className="settings-page-wrapper">
            <div className="settings">
            <Form
            template={template}
            watchFields={['first', 'last']}
            onSubmit={onSubmit}
            uploadedStateFunc={uploadedStateFunc}

          >
            {industries.length && <MazhrDD
              id={'target_profile'}
              options={industries}
              errorContent={"Select Job Department"}
              defaultValue={companyData?.industry?.name}
              onSelectFunc={onSelectIndustry}
            />}

              <FormGroup>
                <label style={{color : "#979797"}} htmlFor="Candidates/Team Members names hidden">Hide Candidates/Team Members name </label>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography><b>OFF</b></Typography>
                  <Switch
                      checked={anonymouseResultsStatus}
                      onChange={handleAnonymousResultChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <Typography><b>ON</b></Typography>
                </Stack>
              </FormGroup>
              <br/>

            {loader ?
              <Button btnstyle={'mazhrBtn light-grey'}>
                <Loader />
              </Button>

              :
              <Button type='submit' btnstyle={'mazhrBtn default-green white'} onSubmit={onSubmit}>
                Save changes
              </Button>
          }
              <div style={{marginTop: "20px", display: "flex"}}>
                <i className="pi pi-info-circle" style={{'fontSize': '2em', marginRight: "10px"}}></i>
                <p>If you wish to delete your account, send us a request to delete or deactivate your
                  account in Help Center by filling out the form, or contacting us via email info@mazhr.com.
                  Please note that after deletion, all your data and information will be lost and cannot
                  be recovered.</p>
                </div>
        </Form>
            </div>
          </div>}
        </div> :
          <Loading />
      }
    </>
  )
}

export default Settings
